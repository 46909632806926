<template>
    <div class="board__input">
        <div class="mb-8 mb-lg-20 text-right">
            <p class="page-text page-text--sm grey-6--text line-height-1"><span class="primary--text">*</span> 필수입력 항목입니다.</p>
        </div>
        <div class="form--primary">
            <form-row tit="제목" pointer>
                <v-text-field v-model="board.subject" placeholder="제목을 입력하세요." outlined hide-details />
            </form-row>
            <form-row tit="카테고리" pointer>
                <v-select v-model="board._category" :items="categoryItems" placeholder="--선택--" outlined hide-details />
            </form-row>
            <form-row tit="썸네일" pointer>
                <v-file-input v-model="board.thumb" placeholder="사이즈 (520*372)" outlined hide-details />
            </form-row>
            <form-row tit="칼럼 내용" pointer>
                <naver-smarteditor v-model="board.content" />
            </form-row>
        </div>

        <div class="v-btn--group">
            <v-btn outlined color="grey-b3" class="min-w-120px min-w-lg-160px" @click="$router.go(-1)">
                <span class="grey-6--text">리스트</span>
            </v-btn>
            <v-btn color="primary" class="min-w-120px min-w-lg-160px" @click="save()">
                칼럼쓰기
            </v-btn>
        </div>
    </div>
</template>

<script>
import api from "@/api";
import FormRow from "@/components/dumb/form-row.vue";
import NaverSmarteditor from "@/components/plugins/naver/naver-smarteditor.vue";
import boards from "@/api/console/boards";

export default {
    components: {
        FormRow,
        NaverSmarteditor,
    },

    data() {
        return {
            category: {
                children: [],
            },

            board: {
                _category: null,

                subject: null,
                code: "column",

                thumb: null,

                content: null,
            },
        };
    },
    async mounted() {
        await this.init();
    },
    methods: {
        async init() {
            let { category } = await api.v1.boards.categories.get({ code: "column" });
            this.category = category;
        },
        validate() {
            try {
                if (!this.board.subject) throw new Error("제목을 입력해주세요");
                if (!this.board._category) throw new Error("카테고리을 선택해주세요");
                if (!this.board.thumb) throw new Error("썸네일을 선택해주세요");
                if (!this.board.content) throw new Error("내용을 입력해주세요");

                return true;
            } catch (error) {
                alert(error.message);
                return false;
            }
        },
        async save() {
            if (this.validate()) {
                let { board } = await api.v1.boards.post({ ...this.board, thumb: null });

                if (this.board.thumb) await api.v1.boards.postThumb(board, this.board.thumb);

                alert("작성되었습니다");

                this.$router.go(-1);
            }
        },
    },
    computed: {
        categoryItems() {
            return this.category.children.map((el) => {
                return { text: el.name, value: el._id };
            });
        },
    },
};
</script>
